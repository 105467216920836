import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const countryListServices = createAsyncThunk(
  "countryListServices",
  async () => {
    try {
      let url = `${BASE_URL}/country/country-code-list`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);


export const stateListServices = createAsyncThunk(
  "stateListServices",
  async (stateId) => {
    try {
      let url = `${BASE_URL}/state/state-list/${stateId}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);


export const planCouponServices = createAsyncThunk(
  "planCouponServices",
  async (subscriptionPlanId) => {
    try {
      // let url = `${BASE_URL}/coupon/coupon-list?itemsPerPage=${10}&pageNumber=${1}&status=${2}&couponType=${1}`;

      let url = `${BASE_URL}/coupon/coupon-list-by-subscription-plan?subscriptionPlanId=${subscriptionPlanId}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);


export const addFrontPageBannerListServices = createAsyncThunk(
  "addFrontPageBannerListServices",
  async (type) => {
    try {
      let url = `https://api.codingyaari.com/English-Yaariapi/banner/frontpage-banner-list?platformType=${1}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const createCheckOutServices = createAsyncThunk(
  "createCheckOutServices",
  async (payload) => {
    try {
      // let url = `${BASE_URL}/coupon/coupon-list?itemsPerPage=${10}&pageNumber=${1}&status=${2}&couponType=${1}`;

      let url = `${BASE_URL}/checkout/create-checkout`;
      const res = await axios.post(url, payload);
      return res.data;
    } catch (error) {
      handleError(error)
      throw error;
    }
  }
);



export const readNotifactionServices = createAsyncThunk(
  "readNotifactionServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/notification/read-user-notification`;
      const res = await axios.post(url, {});
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);


export const readSingleNotifactionServices = createAsyncThunk(
  "readSingleNotifactionServices",
  async (payload) => {
    try {
      let url = `${BASE_URL}/notification/read-single-notification`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);


export const addEYTagsListServices = createAsyncThunk(
    "addEYTagsListServices",
    async (id) => {
        try {
            let url = `${BASE_URL}/tags/tag-list-by-type?tagType=${1}`;
            const res = await axios.get(url);
            return res.data;
        } catch (error) {
            handleError(error);
            throw error;
        }
    }
);

