import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";

export const myClassListServices = createAsyncThunk(
  "myClassListServices",
  async (params) => {

    try {
      // let url = `${BASE_URL}/booking/user-my-classes-list?status=${type}`;
      let url = `${BASE_URL}/session/user-my-classes-list?status=${params?.status}&itemsPerPage=20&pageNumber=${params?.pageNumber}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const joinClassTimeServices = createAsyncThunk(
  "joinClassTimeServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/add-user-join-timestamp`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const endClassServices = createAsyncThunk(
  "endClassServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/session/add-user-end-class-timestamp`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const classReportServics = createAsyncThunk(
  "classReportServics",
  async (id) => {
    try {
      let url = `${BASE_URL}/feedback/user-feedback-report?sessionId=${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

// export const joinClassServices = createAsyncThunk(
//   "joinClassServices",
//   async (payload) => {
//     // console.log(payload);
//     try {
//       let url = `${BASE_URL}/booking/join-session-details`;
//       const res = await axios.post(url,payload);
//       return res.data;

//     } catch (error) {

//     //   console.log(error);
//       //   console.log(error);
//         handleError(error);
//       throw error;
//     }
//   }
// );

export const joinClassServices = async (payload) => {
  let url = `${BASE_URL}/session/join-session-details`;
  const response = await axios.post(url, payload);
  return response.data;
};


export const agoraAquireServices = async (payload) => {
  let url = `https://api.agora.io/v1/apps/3632367747994166aee1275a8509d1b3/cloud_recording/acquire`;
  const response = await axios.post(url, payload);
  return response.data;
};





export const classFeedBackServices = createAsyncThunk(
  "classFeedBackServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session-feedbacks/submit-session-feedback`;
      const res = await axios.post(url,payLoad);
      return res.data;
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }
);



export const startRecordingServices = createAsyncThunk(
  "startRecordingServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session/start-recording`;
      const res = await axios.post(url,payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);




export const endRecordingServices = createAsyncThunk(
  "endRecordingServices",
  async (payLoad) => {
    try {
      let url = `${BASE_URL}/session/end-recording`;
      const res = await axios.post(url,payLoad);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const cancelSessionServices = createAsyncThunk(
  "cancelSessionServices",
  async (id) => {
    try {
      let url = `${BASE_URL}/session/cancel-session-by-user?sessionId=${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);


export const sessionNotesServices = createAsyncThunk(
  "sessionNotesServices",
  async (pageNumber) => {
    try {
      let url = `${BASE_URL}/session-notes/user-session-notes-list?pageNumber=${pageNumber}&itemsPerPage=20`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

// 3632367747994166aee1275a8509d1b3

// export const joinClassServices = async (payload) => {
//     // console.log(payload);
//     try {
//       let url = `${BASE_URL}/booking/join-session-details`;
//       const res = await axios.post(url,payload);
//       return res.data;

//     } catch (error) {

//     //   console.log(error);
//       //   console.log(error);
//         handleError(error);
//       throw error;
//     }
//   }


export const askRecordingServices = createAsyncThunk(
  "askRecordingServices",
  async (payload) => {
    // console.log(payload);
    try {
      let url = `${BASE_URL}/youtube-recording/ask-youtube-recording`;
      const res = await axios.post(url,payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);



export const resentSessionServices = createAsyncThunk(
  "resentSessionServices",
  async (pageNumber) => {
    try {
      let url = `${BASE_URL}/session/recent-session-history?pageNumber=${1}&itemsPerPage=10`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);